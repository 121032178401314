// -- Home Intro -- //

$ns: '.component-home-intro';

#{$ns} {
  padding-bottom: 40px;
  border-bottom: 1px solid #707070;
  margin-bottom: 100px;

  @include at-tablet {
    padding-bottom: 100px;
  }

  &__text {
    font-size: 2em;
    margin-bottom: 60px;
    font-weight: 600;

    @include at-tablet {
      font-size: 3em;
    }
  }

  &__description {
    font-family: $share-tech-mono;

    li {
      margin-bottom: 31px;
      margin-left: 40px;

      &:before {
        color: solid-color('code-blue');
        content: '//';
        margin-left: -40px;
        margin-right: 20px;
      }
    }
  }
}
