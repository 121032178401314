// -- Work Hero -- //

$ns: '.component-work-hero';

#{$ns} {
  line-height: 0;
  position: relative;

  &__video {
    position: relative;

    @include at-tablet {
      &:hover {
        &.image:after {
          visibility: visible;
          opacity: 1;
        }

        #{$ns}__video-content {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    &.image:after {
      content: '';
      background-color: rgba(0,0,0,0.8);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: all 0.2s ease-in-out;

      @include at-tablet {
        visibility: hidden;
        opacity: 0;
        z-index: 1;
      }
    }

    &-container {
    }

    &-content {
      width: 100%;
      position: relative;
      text-align: center;
      padding: 25px 10px;
      transition: all 0.2s ease-in-out;
      padding: 0px;
      text-align: left;
      position: absolute;
      top: 50%;
      left: 0px;
      width: 100%;
      z-index: 2;
      line-height: initial;
      text-align: center;

      @include at-tablet {
        text-align: left;
        padding-left: 10%;
        visibility: hidden;
        opacity: 0;
      }
    }

    &-title {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.8px;
      margin-bottom: 20px;

      @include at-tablet {
        line-height: 1.2;
        font-size: 28px;
        letter-spacing: 0.05em;
      }
    }

    &-link {
      @include btn--primary;

      &.close {
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 1;
      }
    }
  }
}

.video-aspect {
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
