// -- Layouts -- //

/*
 * DO NOT TOUCH
 *
 * this is here just in case we need to style all "layout"s
*/

$ns: '.layout';

#{$ns} {
  opacity: 0;
  padding-bottom: 212px;
  overflow: hidden;
}
