// -- Reveal -- //

$ns: '.component-reveal';

#{$ns} {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s ease-in-out;

  &[data-reveal='true'] {
    opacity: 1;
    transform: translateY(0);
  }
}
