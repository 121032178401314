// -- Container -- //

$ns: '.component-container';

#{$ns} {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;

  &.small {
    width: 83.33%;
    max-width: 1200px;
  }
}
