// -- Typography Mixins -- //

@mixin link-underline {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: -3px;
    display: block;
    border-bottom: 1px solid;
    margin-top: 2px;
    transform: scaleX(0);
    transform-origin: 100% 50%;
    transition: transform 0.25s ease-in-out;
  }

  &:hover {
    &:after {
      transform: scaleX(1);
      transition: transform 0.25s ease-in-out;
      transform-origin: 0 50%;
    }
  }
}

@mixin btn--primary {
  border: solid 1px #FFF;
  border-radius: 3px;
  background-color: transparent;
  font-size: 14px;
  padding: 12px 26px;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  position: relative;
  z-index: 2;
  line-height: 1;
  color: #FFF;

  &:hover {
    background-color: transparent;
    color: #262626;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 0%;
    z-index: -1;
    background-color: #FFF;
    transition: all 350ms ease 30ms;
  }

  &:hover {
    &:after {
      height: 100%;
    }
  }
}
