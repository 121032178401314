// -- Home Featured Work -- //

$ns: '.component-home-featured-work';

#{$ns} {
  &__text {
    margin-bottom: 60px;
    font-size: 2em;
    font-weight: 600;

    @include at-tablet {
      font-size: 3em;
    }
  }

  &__items {
    margin-bottom: 37px;

    @include at-tablet {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 36px;
    }
  }

  &__item {
    margin-bottom: 37px;
    position: relative;

    @include at-tablet {
      margin-bottom: 0;

      &:hover {
        #{$ns}__item--logo {
          visibility: hidden;
          opacity: 0;
        }

        #{$ns}__item--hover {
          box-shadow: 3px 3px 13px 0px rgba(0, 0, 0, 0.75);
          opacity: 1 !important;
          visibility: visible;
        }
      }
    }

    &-bulldog-drummond img {
      min-width: 110px;
    }

    &-pear-sports img {
      min-width: 125px;
    }

    &-vizio-tvp img {
      min-width: 150px;
    }

    &-bond-wedding img {
      max-width: 175px;
    }
  }

  &__item--logo,
  &__item--hover {
    height: 150px;
    display: grid;
    justify-content: center;
    align-items: center;

    @include at-tablet {
      height: 225px;
    }

    @include at-laptop {
      height: 350px;
    }
  }

  &__item--logo {
    transition: all 0.5s ease-in-out;
    visibility: visible;
    opacity: 1;

    img {
      width: 100%;
    }
  }

  &__item--hover {
    transition: all 0.5s ease-in-out;
    background-position: center center;
    background-size: cover;
    box-shadow: 3px 3px 13px 0px rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 3em;
    color: solid-color('white');
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    padding: 0 15%;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.7);
      z-index: 0;
    }

    span {
      z-index: 1;
    }
  }
}
