// -- Typography -- //

// everything

html {
  line-height: 0;
  // prevent font scaling
  /* stylelint-disable */
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  /* stylelint-enable */
}

body {
  -webkit-font-smoothing: antialiased;
  color: solid-color('white');
  background-color: #191919;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

// headings
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}

h1 {
  font-size: $base-font-size + 1;
}

h2 {
  font-size: $base-font-size + 0.5;
}

h3 {
  font-size: $base-font-size + 0.17;
}

h4 {
  font-size: $base-font-size;
}

h5 {
  font-size: $base-font-size - 0.17;
}

h6 {
  font-size: $base-font-size - 0.33;
}

p,
ul,
li {
  line-height: 1.15;
}

/* WebKit/Blink Browsers */
::selection {
  background: solid-color('code-blue');
}

/* Gecko Browsers */
::-moz-selection {
  background: solid-color('code-blue');
}
