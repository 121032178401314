// -- Header -- //

$ns: '.component-header';

#{$ns} {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;

  @include at-laptop {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &__logo {
    width: 80px;
  }
}
