// -- Element Variables -- //

/* ============================================================================
 * Buttons
*/

$buttons-list: 'button',
  'input[type="button"]',
  'input[type="reset"]',
  'input[type="submit"]';

/* ============================================================================
 * Inputs
*/

$text-inputs-list: 'input[type="color"]',
  'input[type="date"]',
  'input[type="datetime"]',
  'input[type="datetime-local"]',
  'input[type="email"]',
  'input[type="month"]',
  'input[type="number"]',
  'input[type="password"]',
  'input[type="search"]',
  'input[type="tel"]',
  'input[type="text"]',
  'input[type="time"]',
  'input[type="url"]',
  'input[type="week"]',
  'input:not([type])',
  'textarea';

$base-border-color: #DDD;
$base-border: 1px solid $base-border-color;
$base-border-radius: 3px;

$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
